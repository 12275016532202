import { useState, useReducer } from "react";
import "../styles/SearchTable.scss";
import { Table, Form, Input, Modal, Button, Pagination, Loader } from 'semantic-ui-react';
import _ from 'lodash';
import DatePicker from "react-datepicker";
import { parcelService } from '../services';
import { isMinOrMaxDate, formatDateAndTimeOnTwoLines, formatDateAndTime } from "../utils/datehelper";

let trackingId = 0;

function tableReducer(state, action) {
    switch (action.type) {
        case 'CHANGE_SORT':
            if (state.column === action.column) {
                return {
                    ...state,
                    data: state.data.slice().reverse(),
                    direction: state.direction === 'ascending' ? 'descending' : 'ascending',
                }
            }

            return {
                column: action.column,
                data: _.sortBy(state.data, [action.column]),
                direction: 'ascending',
            }
        default:
            throw new Error()
    }
}

const SearchParcelTable2 = (props) => {
    const { tableData, loading } = props;
    const [trackingHistoryArr, setTrackingHistoryArr] = useState([]);
    const [open, setOpen] = useState(false);
    const [modalLoading, setModalLoading] = useState(false)
    const [state, dispatch] = useReducer(tableReducer, {
        column: null,
        data: tableData,
        direction: null,
    })
    const { column, data, direction } = state;
    const [query, setQuery] = useState("");
    const [date, setDate] = useState(new Date());
    const headerArr = tableData && tableData.length > 0 ? Object.keys(tableData[0]) : [];
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage,] = useState(25);

    headerArr.forEach(header => {
        const newHeader = header.replace('_', ' ')
        const arr = newHeader.split(" ")
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const capHeader = arr.join(" ").replace(/([A-Z])/g, ' $1').trim();
        headerArr.push(capHeader)
    });

    const search = (data) => {
        const keys = data && data.length > 0 ? Object.keys(data[0]) : [];
        return data.filter((item) => keys.some(key => item[key].toString().toLowerCase().includes(query.toLowerCase())));
    }

    const style = {
        headerCell: {
            backgroundColor: "#2d8fc4",
            color: "white",
            border: "0.5px solid #ddd",
            fontSize: '1rem',
            textAlign: 'center',
        },
        tCell: {
            fontSize: '1rem',
            textAlign: 'center',
        },
        tCellLink: {
            fontSize: '1rem',
            textAlign: 'center',
            color: '#3b3be4',
            cursor: 'pointer'
        },
    }

    const Headers = () => {
        const headers = Object.keys(tableData && tableData[0] ? tableData[0] : {});
        return (
            headers.map((col, idx) => {
                const newHeader = col.replace('_', ' ')
                const arr = newHeader.split(" ")
                for (var i = 0; i < arr.length; i++) {
                    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                }
                const capHeader = arr.join("_").replace(/([A-Z])/g, ' $1').trim();

                return (
                    <Table.HeaderCell
                        key={idx}
                        style={style.headerCell}
                        sorted={column === col ? direction : null}
                        onClick={() => dispatch({ type: 'CHANGE_SORT', column: col })}
                    >
                        {capHeader}
                    </Table.HeaderCell>
                )
            })
        )
    }

    const handleModal = (id) => {
        setOpen(true)
        setModalLoading(true)
        try {
            // pull back the route data      
            let payload = { queryParams: { trackingNumber: id.toString() }, payload: null };
            parcelService.GetTrackingHistory(payload)
                .then(response => {
                    if (response.success) {
                        let historyArr = response.result;
                        historyArr.sort((a, b) => new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime());
                        setTrackingHistoryArr(historyArr)
                        setModalLoading(false)
                    }
                    else {
                        setModalLoading(false)
                        console.error('Error pulling back route data', response.message);
                    }
                });
        } catch (error) {
            console.log(error)
            setModalLoading(false)
        }
        trackingId = id
    }
    const handlePageChange = (e, { activePage }) => {
        setCurrentPage(activePage);
    }
    let currentPageData = search(data).slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <div className="app">
            <Form>
                <Form.Group>
                    {/* <Dropdown placeholder='All Carriers' fluid multiple selection options={carriers} id="dropdown" onChange={setSelected} /> */}
                    <Button content='Go Back' icon='left arrow' labelPosition='left' color='blue' onClick={() => props.goBack(true)} />
                    <div id="date-picker" style={{ marginLeft: '5px' }} >
                        <DatePicker selected={date} onChange={(date) => setDate(date)} />
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <Input icon='search' placeholder='Search...' onChange={e => setQuery(e.target.value)} />

                </Form.Group>
            </Form>
            {loading ? <Loader active size="massive">Loading...</Loader> : tableData && tableData.length > 0 ?
                <div style={{ width: '100%', marginTop: '10px', textAlign: 'center' }}>
                    <Table sortable celled >
                        <Table.Header>
                            <Table.Row>
                                <Headers />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {currentPageData.map((row, idx) => {
                                let bgc;
                                if (row.exceptions) {
                                    if (row.exceptions.includes("Missed")) { bgc = '#C8B6FC' }
                                    else if (row.exceptions.includes("Late")) { bgc = '#FCC2C2' }
                                    else if (row.exceptions.includes("Early")) { bgc = '#F5B642' }
                                    else bgc = '#fff'
                                }

                                if (row.trackingNumber) {
                                    return (
                                        <Table.Row key={idx} style={{ backgroundColor: bgc, }}>
                                            {Object.keys(row).map((col, idx) => {
                                                if (col === 'trackingNumber') {
                                                    return <Table.Cell key={idx} style={style.tCellLink} onClick={() => handleModal(row[col].toString())}> {row[col].toString()}</Table.Cell>
                                                } else if (col.toString().toLowerCase().includes('date')) {
                                                    if (isMinOrMaxDate(row[col])) {
                                                        return <Table.Cell key={idx} style={style.tCell}>-</Table.Cell>
                                                    }
                                                    else {
                                                        return <Table.Cell key={idx} style={style.tCell}> {formatDateAndTimeOnTwoLines(row[col])}</Table.Cell>
                                                    }
                                                }
                                                else {
                                                    return <Table.Cell key={idx} style={style.tCell}> {row[col].toString()}</Table.Cell>
                                                }
                                            })}
                                        </Table.Row>
                                    )
                                } else return ""
                            })}
                        </Table.Body>
                    </Table>
                    <Pagination
                        activePage={currentPage}
                        onPageChange={handlePageChange}
                        siblingRange={1}
                        pointing
                        secondary
                        totalPages={Math.ceil(data.length / itemsPerPage)}
                    />
                </div>
                :
                <div style={{ width: '100%', marginTop: '10px' }}>
                    <h1> No Data to display</h1>
                </div>
            }
            <Modal
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                size="large"
            >
                <Modal.Header>{`Tracking Number: ${trackingId}`}</Modal.Header>

                <Modal.Content scrolling>
                    {modalLoading ?
                        <h1>Loading...</h1>
                        :
                        trackingHistoryArr.length > 0 ?
                            <Table celled >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={style.headerCell}>Package ID</Table.HeaderCell>
                                        <Table.HeaderCell style={style.headerCell}>Date</Table.HeaderCell>
                                        <Table.HeaderCell style={style.headerCell}>Event Description</Table.HeaderCell>
                                        <Table.HeaderCell style={style.headerCell}>Status</Table.HeaderCell>
                                        <Table.HeaderCell style={style.headerCell}>City</Table.HeaderCell>
                                        <Table.HeaderCell style={style.headerCell}>State/Province</Table.HeaderCell>
                                        <Table.HeaderCell style={style.headerCell}>Zip Code</Table.HeaderCell>
                                        {/* <Table.HeaderCell style={style.headerCell}>Country</Table.HeaderCell> */}
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {trackingHistoryArr.map((rows, idx) => {
                                        return (
                                            <Table.Row key={idx}>
                                                {/* {Object.keys(row).map((col, idx) => {
                                                    if (col === 'eventDate') {
                                                        return (
                                                            <Table.Cell key={idx} style={style.tCell}>
                                                                <p>{formatDateAndTime(row[col])}</p>
                                                            </Table.Cell>
                                                        )
                                                    }
                                                    else if (col === null || col === undefined || col === "") {
                                                        console.log("here");
                                                        return <Table.Cell key={idx} style={style.tCell}>=</Table.Cell>
                                                    } else
                                                        return <Table.Cell key={idx} style={style.tCell}> {row[col].toString()}</Table.Cell>
                                                })} */}
                                                {/* {Object.keys(rows).includes('state') ? 'true' : console.log(rows)} */}
                                                {Object.keys(rows).includes('state') ? null : (rows.state = '', rows.countryCode = '')}
                                                {/* {Object.keys(rows).includes('state') ? null : (rows.countryCode = '-')} */}
                                                {Object.entries(rows).map(([row, col], idx) => {

                                                    if (row === 'eventDate') {
                                                        return (
                                                            <Table.Cell key={idx} style={style.tCell}>
                                                                <p>{formatDateAndTime(col)}</p>
                                                            </Table.Cell>
                                                        )
                                                    }
                                                    else
                                                        return <Table.Cell key={idx} style={style.tCell}> {col.toString()}</Table.Cell>
                                                })}
                                            </Table.Row>
                                        )
                                    })
                                    }
                                </Table.Body>
                            </Table>
                            :
                            <h1>No tracking history yet...</h1>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button onClick={() => setOpen(false)} positive>
                        Ok
                    </Button>
                    <br />
                    <br />
                    <br />
                </Modal.Actions>
            </Modal>
        </div>
    );
}

export default SearchParcelTable2;