import _ from 'lodash'
import { useReducer } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Table, Icon } from 'semantic-ui-react'
import "../styles/RouteId.scss"
import { useEffect } from 'react'

type Routes = {
  [index: number]: Route
}

type Route = {
  Stop_ID: number,
  Seq: number,
  Address: string,
  Type: number,
  Pcs: number,
  References: string,
  Time_Min_Pref_Max: string,
  Arrival_Time: string,
  Completion_Time: string,
  Elapsed: string,
  Status: string,
  Exceptions: string,
  Courier: string,
  onTime: string
}

type State = {
  column: string | null,
  data: any,
  direction: "ascending" | "descending" | null,
}

type Action = {
  type: string,
  column: string
}

const tableData: Routes = [
  { Stop_ID: 129719, Seq: 0, Address: '433 E 6th St Mesa, AZ 85203', Type: 1, Pcs: 1, References: '', Time_Min_Pref_Max: "05:00 AM/ 05:00 AM / 05:15 AM", Arrival_Time: "", Completion_Time: '', Elapsed: '', Status: 'pending', Exceptions: '2 - Missed', Courier: '	CourierLogistics', onTime: "55.08%s" },
  { Stop_ID: 129719, Seq: 0, Address: '433 E 6th St Mesa, AZ 85203', Type: 1, Pcs: 1, References: '', Time_Min_Pref_Max: "05:00 AM/ 05:00 AM / 05:15 AM", Arrival_Time: "", Completion_Time: '', Elapsed: '', Status: 'pending', Exceptions: '2 - Missed', Courier: '	CourierLogistics', onTime: "55.08%s" },
  { Stop_ID: 129719, Seq: 0, Address: '433 E 6th St Mesa, AZ 85203', Type: 1, Pcs: 1, References: '', Time_Min_Pref_Max: "05:00 AM/ 05:00 AM / 05:15 AM", Arrival_Time: "", Completion_Time: '', Elapsed: '', Status: 'pending', Exceptions: '2 - Missed', Courier: '	CourierLogistics', onTime: "55.08%s" },
  { Stop_ID: 129719, Seq: 0, Address: '433 E 6th St Mesa, AZ 85203', Type: 1, Pcs: 1, References: '', Time_Min_Pref_Max: "05:00 AM/ 05:00 AM / 05:15 AM", Arrival_Time: "", Completion_Time: '', Elapsed: '', Status: 'pending', Exceptions: '2 - Missed', Courier: '	CourierLogistics', onTime: "55.08%s" },
  { Stop_ID: 129719, Seq: 0, Address: '433 E 6th St Mesa, AZ 85203', Type: 1, Pcs: 1, References: '', Time_Min_Pref_Max: "05:00 AM/ 05:00 AM / 05:15 AM", Arrival_Time: "", Completion_Time: '', Elapsed: '', Status: 'pending', Exceptions: '2 - Missed', Courier: '	CourierLogistics', onTime: "55.08%s" },
  { Stop_ID: 129719, Seq: 0, Address: '433 E 6th St Mesa, AZ 85203', Type: 1, Pcs: 1, References: '', Time_Min_Pref_Max: "05:00 AM/ 05:00 AM / 05:15 AM", Arrival_Time: "", Completion_Time: '', Elapsed: '', Status: 'pending', Exceptions: '2 - Missed', Courier: '	CourierLogistics', onTime: "55.08%s" },
  { Stop_ID: 129719, Seq: 0, Address: '433 E 6th St Mesa, AZ 85203', Type: 1, Pcs: 1, References: '', Time_Min_Pref_Max: "05:00 AM/ 05:00 AM / 05:15 AM", Arrival_Time: "", Completion_Time: '', Elapsed: '', Status: 'pending', Exceptions: '2 - Missed', Courier: '	CourierLogistics', onTime: "55.08%s" },
  { Stop_ID: 129719, Seq: 0, Address: '433 E 6th St Mesa, AZ 85203', Type: 1, Pcs: 1, References: '', Time_Min_Pref_Max: "05:00 AM/ 05:00 AM / 05:15 AM", Arrival_Time: "", Completion_Time: '', Elapsed: '', Status: 'pending', Exceptions: '2 - Missed', Courier: '	CourierLogistics', onTime: "55.08%s" },
  { Stop_ID: 129719, Seq: 0, Address: '433 E 6th St Mesa, AZ 85203', Type: 1, Pcs: 1, References: '', Time_Min_Pref_Max: "05:00 AM/ 05:00 AM / 05:15 AM", Arrival_Time: "", Completion_Time: '', Elapsed: '', Status: 'pending', Exceptions: '2 - Missed', Courier: '	CourierLogistics', onTime: "55.08%s" },
  { Stop_ID: 129719, Seq: 0, Address: '433 E 6th St Mesa, AZ 85203', Type: 1, Pcs: 1, References: '', Time_Min_Pref_Max: "05:00 AM/ 05:00 AM / 05:15 AM", Arrival_Time: "", Completion_Time: '', Elapsed: '', Status: 'pending', Exceptions: '2 - Missed', Courier: '	CourierLogistics', onTime: "55.08%s" }
]

function exampleReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'CHANGE_SORT':
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.slice().reverse(),
          direction:
            state.direction === 'ascending' ? 'descending' : 'ascending',
        }
      }

      return {
        column: action.column,
        data: _.sortBy(state.data, [action.column]),
        direction: 'ascending',
      }
    default:
      throw new Error()
  }
}

const RouteId = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(exampleReducer, {
    column: null,
    data: tableData,
    direction: null,
  })
  const { column, data, direction } = state;

  useEffect(() => {
    if (localStorage.token === null || localStorage.token === undefined) {
      const navTo = `/`
      navigate(navTo)
    }
  }, [navigate]);

  const handleNavigate = (route: any) => {
    const navTo = `/${route}`
    navigate(navTo)
  }

  return (
    <div className='route-container' >
      <div className="buttons-container">
        <Button.Group size='big' width='equal'>
          <Button id="bttn-carrier" onClick={() => handleNavigate('Carriers')}>
            <Icon name="truck" />Carrier View
          </Button>
          <Button id="bttn-routes" onClick={() => handleNavigate('Routed')}>
            <Icon name="road" />Routes View
          </Button>
          <Button id="bttn-map" onClick={() => handleNavigate('Map')}>
            <Icon name="map" />Map View
          </Button>
        </Button.Group>
      </div>

      <Table sortable celled fixed>
        <Table.Header id="route-table-header">
          <Table.Row >
            <Table.HeaderCell
              id="route-header-cell"
              sorted={column === 'map' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'Stop_ID' })}
            >
              Stop ID
            </Table.HeaderCell>
            <Table.HeaderCell
              id="route-header-cell"
              sorted={column === 'route_id' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'Seq' })}
            >
              Seq
            </Table.HeaderCell>
            <Table.HeaderCell
              id="route-header-cell"
              sorted={column === 'description' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'Address' })}
            >
              Address
            </Table.HeaderCell>
            <Table.HeaderCell
              id="route-header-cell"
              sorted={column === 'fleet_name' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'Type' })}
            >
              Type
            </Table.HeaderCell>
            <Table.HeaderCell
              id="route-header-cell"
              sorted={column === 'start_time' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'Pcs' })}
            >
              Pcs
            </Table.HeaderCell>
            <Table.HeaderCell
              id="route-header-cell"
              sorted={column === 'end_time' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'References' })}
            >
              References
            </Table.HeaderCell>
            <Table.HeaderCell
              id="route-header-cell"
              sorted={column === 'stops' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'Time_Min_Pref_Max' })}
            >
              Time Min/Pref/Max
            </Table.HeaderCell>
            <Table.HeaderCell
              id="route-header-cell"
              sorted={column === 'completed' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'Arrival_Time' })}
            >
              Arrival Time
            </Table.HeaderCell>
            <Table.HeaderCell
              id="route-header-cell"
              sorted={column === 'exceptions' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'Completion_Time' })}
            >
              Completion Time
            </Table.HeaderCell>
            <Table.HeaderCell
              id="route-header-cell"
              sorted={column === 'courier' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'Elapsed' })}
            >
              Elapsed
            </Table.HeaderCell>
            <Table.HeaderCell
              id="route-header-cell"
              sorted={column === 'today_onTime' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'Status' })}
            >
              Status
            </Table.HeaderCell>
            <Table.HeaderCell
              id="route-header-cell"
              sorted={column === 'prior_onTime' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'Exceptions' })}
            >
              Exceptions
            </Table.HeaderCell>
            <Table.HeaderCell
              id="route-header-cell"
              sorted={column === 'prior_onTime' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'Courier' })}
            >
              Courier
            </Table.HeaderCell>
            <Table.HeaderCell
              id="route-header-cell"
              sorted={column === 'prior_onTime' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'onTime' })}
            >
              Prior 60 Days On Time %
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map((row: Route, idx: number) => {
            let bgc = '#fff';
            if (row.Exceptions.includes("Missed")) { bgc = '#C8B6FC' }
            else if (row.Exceptions.includes("Late")) { bgc = '#FCC2C2' }
            else if (row.Exceptions.includes("Early")) { bgc = '#f5b642' }
            return (
              <Table.Row style={{ backgroundColor: bgc }} key={idx}>
                {Object.keys(row).map((cell: string | number, idx: number) => {
                  const info = row[cell as keyof Route]
                  if (cell === "route_id")
                    return <Table.Cell id="route-table-data" key={idx}><Link to={`/Routed/${info}`} state={{ id: info }}>{info}</Link></Table.Cell>
                  return <Table.Cell id="route-table-data" key={idx}>{info}</Table.Cell>
                })}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </div>
  )
}

export default RouteId;